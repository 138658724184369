// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-header-error {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  background-color: #007602;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  height: 56px;
  color: white;

  .error-content {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorHeader/HeaderError.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,WAAW;EACX,WAAW;EACX,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,eAAe;EACf,YAAY;EACZ,YAAY;;EAEZ;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;EACV;AACF","sourcesContent":[".main-header-error {\n  position: fixed;\n  top: 0;\n  z-index: 10;\n  width: 100%;\n  background-color: #007602;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 1rem;\n  height: 56px;\n  color: white;\n\n  .error-content {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
