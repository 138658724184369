import { Button, Layout } from "antd";

import "./HeaderError.css";
import { FaExclamationTriangle, FaRedo } from "react-icons/fa";

const { Header: AntdHeader } = Layout;

export const ErrorHeader = ({ error }: { error: Error }) => (
  <AntdHeader className="main-header-error">
    <div className="error-content">
      <FaExclamationTriangle
        style={{ fontSize: "20px", color: "white", marginRight: "8px" }}
      />
      <div>
        <span className="error-message-container">
          Ocorreu um problema ao carregar o componente: {error.message}
        </span>
      </div>
    </div>
    <Button
      icon={<FaRedo style={{ fontSize: "16px" }} />}
      type="primary"
      onClick={() => window.location.reload()}
    >
      Tentar Novamente
    </Button>
  </AntdHeader>
);

export default ErrorHeader;
