import { Button, Divider, Dropdown, Layout, MenuProps } from "antd";
import { LuUser2 } from "react-icons/lu";
import { BsCalendar2EventFill, BsFillBellFill } from "react-icons/bs";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { AiOutlineLogout, AiOutlineShoppingCart } from "react-icons/ai";
import "./Header.css";
import logoXPRv2Img from "../../assets/logo_xpr_v2.png";
import logoSAMAImg from "../../assets/logo_sama.png";
import logoDAASFYImg from "../../assets/logo_daasfy.png";
import logoXPESSImg from "../../assets/logo_xpess.png";
import logoXPESSLightMode from "../../assets/logo_xpess_light_mode.png";
import avatarImg from "../../assets/avatar.png";
import { useCallback, useEffect, useState } from "react";
import { User } from "../../types";

export interface HeaderProps {
  project: "xpr" | "daasfy" | "sama" | "xpess";
  links?: {
    xpr: string;
    xmro: string;
    xutm: string;
    daasfy: string;
  };
  onAddBalance?: () => void;
  onShoppingCart?: () => void;
  onMyData?: () => void;
  onStatement?: () => void;
  isDarkMode?: boolean;
}

export const Header = ({
  project,
  links = {
    xpr: "https://xpr.xmobots.com/",
    xmro: "https://xmmro.xmobots.com/",
    xutm: "https://brutm.xmobots.com/",
    daasfy: "https://dassfy.xmobots.com/",
  },
  onAddBalance = () => {},
  onShoppingCart = () => {},
  onMyData = () => {},
  onStatement = () => {},
  isDarkMode = false,
}: HeaderProps) => {
  const { Header: AntdHeader } = Layout;

  const [user, setUser] = useState<User>({
    name: "",
    balance: "",
    cashback: "",
    avatar: avatarImg,
  });

  const init = useCallback(async () => {
    const keycloak = window.keycloak;
    if (!keycloak) return;
    const userProfile = await keycloak.loadUserProfile();
    setUser({
      name: `${userProfile?.firstName ?? ""} ${userProfile?.lastName ?? ""}`,
      balance: "223.155,00",
      cashback: "223.155,00",
      avatar: avatarImg,
    });
  }, []);

  const logout = () => {
    const keycloak = window.keycloak;
    if (!keycloak) return;
    keycloak?.logout();
  };

  const menuProfileItens: MenuProps["items"] = [
    {
      key: "1",
      disabled: true,
      label: (
        <div className="user-info">
          <span className="user-name">{user.name}</span>
          <img className="avatar" src={user.avatar} alt="avatar" />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div className="options-row">
          <Button className="option-button">
            <LuUser2 className="option-icon" onClick={onMyData} />
            Meus Dados
          </Button>
          <Button className="option-button">
            <HiOutlineCurrencyDollar
              className="option-icon"
              onClick={onStatement}
            />
            Extrato
          </Button>
          <Button className="option-button">
            <AiOutlineShoppingCart
              className="option-icon"
              onClick={onShoppingCart}
            />
            Carrinho
          </Button>
        </div>
      ),
      disabled: true,
    },
    {
      key: "3",
      label: (
        <div className="user-details">
          <div className="account-icon">
            <span>CONTA</span>
            <img className="logo" src={logoDAASFYImg} alt="logo-daasfy" />
          </div>
          <div className="account-details">
            <div className="account-item">
              <span className="account-item-name">SALDO</span>
              <span className="account-item-type">R$</span>
              <span className="account-item-value">{user.balance}</span>
            </div>
            <div className="account-item">
              <span className="account-item-name">CASHBACK</span>
              <span className="account-item-type">R$</span>
              <span className="account-item-value">{user.cashback}</span>
            </div>
          </div>
          <div className="add-balance" onClick={onAddBalance}>
            <span className="add-balance-title">Adicionar saldo</span>
          </div>
        </div>
      ),
      disabled: true,
    },
    {
      key: "4",
      label: <Divider style={{ margin: 0 }} />,
      disabled: true,
    },
    {
      key: "5",
      label: (
        <div className="tools">
          <div className="tools-title-box">
            <span className="tools-title">FERRAMENTAS</span>
            <span className="tools-title">AVANÇADAS</span>
          </div>
          <div className="tools-options">
            <Button
              className="tools-option-button"
              onClick={() => window.open(links.xpr, "_blank")}
            >
              XPR
            </Button>
            <Button
              className="tools-option-button"
              onClick={() => window.open(links.xmro, "_blank")}
            >
              XMRO
            </Button>
            <Button
              className="tools-option-button"
              onClick={() => window.open(links.xutm, "_blank")}
            >
              XUTM
            </Button>
          </div>
        </div>
      ),
      disabled: true,
    },
    {
      key: "6",
      label: (
        <div className="logout">
          <Button
            className="to-go-back-button"
            onClick={() => window.open(links.daasfy, "_blank")}
          >
            Voltar para DAASFY
          </Button>
          <Button
            type="text"
            className="logout-button"
            icon={<AiOutlineLogout />}
            iconPosition="end"
            onClick={logout}
          >
            Sair
          </Button>
        </div>
      ),
      disabled: true,
    },
  ];

  const getProjectLogo = () => {
    switch (project) {
      case "xpr":
        return logoXPRv2Img;
      case "daasfy":
        return logoDAASFYImg;
      case "sama":
        return logoSAMAImg;
      case "xpess":
        return isDarkMode ? logoXPESSImg : logoXPESSLightMode;
      default:
        return logoXPRv2Img;
    }
  };

  useEffect(() => {
    init();
  }, [init]);

  return (
    <AntdHeader
      className={`main-header ${
        isDarkMode ? "main-header-dark-mode" : "main-header-light-mode"
      }`}
    >
      <img src={getProjectLogo()} alt="logo" />
      <div className="header-right">
        <div className="actions-container">
          <div className="action-button">
            <BsFillBellFill color="#fff" />
            <div className="notification-dot" />
          </div>
          <div className="action-button">
            <BsCalendar2EventFill color="#fff" />
            <div className="notification-dot" />
          </div>
          <Dropdown
            menu={{
              items: menuProfileItens,
              className: `profile-dropdown ${
                isDarkMode
                  ? "profile-dropdown-dark-mode"
                  : "profile-dropdown-light-mode"
              }`,
            }}
            arrow={{ pointAtCenter: true }}
            placement="bottomRight"
            align={{ offset: [20, 25] }}
          >
            <img className="avatar-button" src={user.avatar} alt="avatar" />
          </Dropdown>
        </div>
      </div>
    </AntdHeader>
  );
};

export default Header;
