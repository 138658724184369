import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import Root from "./components/Header/Header";
import ErrorHeader from "./components/ErrorHeader";
import Keycloak from "keycloak-js";

declare global {
  interface Window {
    keycloak: Keycloak;
  }
}

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  errorBoundary(err, info, props) {
    return <ErrorHeader error={err} />;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
